<template>
  <vue-multiselect
    selectLabel="Нажмите Enter чтобы выбрать"
    deselectLabel="Нажмите Enter чтобы убрать"
    selectedLabel="Выбрано"
    tag-placeholder=""
    :taggable="false"
    v-bind="$attrs"
    :value="value"
    @input="input"
  >
    <template v-for="(_, name) of $slots" #[name]><slot :name="name" /></template>
    <template v-for="(_, name) of $scopedSlots" #[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </vue-multiselect>
</template>

<script>
export default {
  name: 'MultiSelect',
  mounted() {
    console.log(this.$listeners);
  },
  computed: {
    value() {
      const propValue = this.$attrs.value;
      let optionEl = null;
      if (this.$attrs.multiple) {
        optionEl = this.$attrs.options.filter(el => propValue.includes(el[this.idField]));
      } else {
        optionEl = this.$attrs.options.find(el => el[this.idField] === propValue);
      }
      console.log('VALUE', optionEl, this.$attrs.options, propValue, this.$attrs.multiple);
      if (!optionEl) {
        return {
          [this.idField]: propValue,
        }
      } else {
        return optionEl;
      }
    },
    idField() {
      return this.$attrs['track-by'] || 'id'
    }
  },
  methods: {
    input(object) {
      console.log(object, this.idField);
      let val = null;
      if (this.$attrs.multiple) {
        val = object.map(el => el[this.idField])
      } else {
        val = object[this.idField];
      }
      console.log(val, 'AAA!')
      this.$emit('input', val);
    }
  }
}
</script>
