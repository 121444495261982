<template>
  <CCard body-wrapper>
    <h1>
      Настройка МойСклад
    </h1>
    <CForm
      class="mt-5"
      @submit.prevent="createOrUpdate"
    >
      <CCard>
        <CCardBody>
          <CInput
            label="Название"
            required
            :invalid-feedback="getScalarFieldError(errors, 'name')"
            :is-valid="isScalarFieldInvalid(errors, 'name') ? false : null"
            :value.sync="settings.name"
          />
          <HiddenInput
            label="Токен"
            required
            :invalid-feedback="getScalarFieldError(errors, 'token')"
            :is-valid="isScalarFieldInvalid(errors, 'token') ? false : null"
            v-model="settings.token"
            @blur="loadAttributes"
          />
          <div class="form-group">
            <label>Поле для поиска по EX-номеру</label>
            <multiselect
              v-model="settings.searchById"
              placeholder="Поиск аттрибута"
              :options="attributes.list"
              :multiple="false"
              track-by="id"
              label="name"
              searchable
              :loading="attributes.loading"
            >
              <template slot="noOptions">
                <div>Аттрибуты не найдены, проверьте правильность поля <strong>Токен</strong></div>
              </template>
              <template slot="noResult">
                <div>Аттрибуты не найдены, проверьте правильность поля <strong>Токен</strong> или наличие полей в МойСклад</div>
              </template>
            </multiselect>
            <div v-if="isScalarFieldInvalid(errors, 'searchById')" class="invalid-feedback">
              {{ getScalarFieldError(errors, 'searchById') }}
            </div>
          </div>
          <hr>
          <CRow>
            <CCol col="auto">
              <loading-button :loading="updating" />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
    <CElementCover
      v-if="!settingsLoaded"
      :style="{zIndex: 2}"
      :opacity="0.8"
    />
  </CCard>
</template>

<script>
import FormMixins from '@/mixins/form-mixins';
import Swal from 'sweetalert2';
import HiddenInput from '@/components/hidden-input';
import LoadingButton from '@/components/loading-button';
import Multiselect from '@/components/custom-multiselect';

export default {
  name: 'MoyskladSettings',
  components: {
    HiddenInput,
    LoadingButton,
    Multiselect
  },
  mixins: [
    FormMixins,
  ],
  mounted() {
    this.load().then(() => this.loadAttributes())
  },
  data() {
    return {
      settings: {
        token: null,
        searchById: null,
      },
      attributes: {
        loading: false,
        list: [],
      },
      errors: {},
      settingsLoaded: false,
      updating: false
    };
  },
  methods: {
    async load() {
      try {
        const {data} = await this.$axios.request({url: 'api/moysklad'});
        this.settings = data;
        this.settingsLoaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    async loadAttributes() {
      console.log('loadAttributes')
      try {
        this.attributes.loading = true;
        const {data} = await this.$axios.request(
          {
            url: 'api/moysklad/external_attributes',
            method: 'POST',
            data: {token: this.settings.token}
          }
        )
        this.attributes.list = data;
      } catch (e) {
        console.error(e)
      } finally {
        this.attributes.loading = false;
      }
    },
    async createOrUpdate() {
      this.updating = true
      this.errors = {}
      try {
        const {data} = await this.$axios.post('api/moysklad', this.settings);
        this.settings = data;
        await Swal.fire(
          'Успешно',
          'Настройки МойСклад сохранены',
          'success',
        );
      } catch (e) {
        if (this.hasErrorsInResponse(e)) {
          this.errors = e.response.data

          this.$nextTick(() => {
            this.showBaseErrors();
          })
        }
        console.error(e);
      } finally {
        this.updating = false;
      }
    }
  }
}
</script>
